.document {
  padding: 1.5rem;
}

.block ul {
  margin-bottom: 0.5rem;
  padding-left: 0;
}

.progress-wrapper {
  margin: 80px auto;
  height: 50vh;
}

.arrow-link {
  margin-top: 1rem;
  display: flex;
}

@media only screen and (min-width: 577px) {
  .document {
    padding: 2rem;
  }
}

.title{
  font-size: 2.2rem;
  line-height: 2.8rem;
}
@media only screen and (max-width: 577px) {
  .title{
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
}

.doc-content h2 {
  font-size: 1.5rem;
}

.doc-content h2,
.doc-content h3,
.doc-content h4,
.doc-content h5 {
  color: var(--primary-dark);
}

/* Hallituksen esitykset: styles for xml-parsed */
.LukuOtsikko {
  display: flex;
  align-items: top;
  color: var(--primary-dark);
}
.OtsikkoNroTeksti {
  font-size: 1.5rem;
  margin: 2rem 10px 1rem 0;
}
.OtsikkoTeksti,
.ValiotsikkoTeksti,
.LihavaKursiiviOtsikkoTeksti {
  margin: 2rem 0 1rem;
}
.OtsikkoTeksti {
  text-transform: uppercase;
}
.Katkoviiva {
  border: none;
  height: 1px;
  margin: 1em 0;
  background: repeating-linear-gradient(
    90deg,
    transparent,
    #666 1px,
    #666 24px,
    transparent 25px,
    transparent 31px
  );
}
.Palstaviiva {
  max-width: 100px;
  border: none;
  border-bottom: 1px solid #666;
  margin: 2rem auto;
}
.Keskiviiva {
  max-width: 160px;
  border: none;
  border-bottom: 1px solid #666;
  margin: 2rem auto;
}
.SisennettyKappaleKooste {
  margin-left: 2rem;
}
.Henkilo {
  margin-right: 8px;
}
.AsemaTeksti {
  margin-right: 5px;
}
.EtuNimi,
.SukuNimi {
  margin-right: 5px;
  font-weight: bold;
}
.KursiiviTeksti,
.LihavaKursiiviTeksti,
.SaadosKursiiviKooste,
.LihavaKursiiviOtsikkoTeksti {
  font-style: italic;
}
.LihavaKursiiviTeksti,
.LihavaTeksti,
.LihavaKursiiviOtsikkoTeksti {
  font-weight: bold;
}
.LihavaTeksti {
  margin-right: 5px;
}

.PaivaysKooste {
  margin: 1rem 0 2rem;
}
.SaadostyyppiKooste {
  font-size: 1.4rem;
  text-align: center;
  margin: 1rem 0;
  font-weight: bold;
}
.SaadosNimekeKooste {
  text-align: center;
  margin: 1rem 0;
  font-weight: bold;
}
.LukuTunnusKooste,
.PykalaTunnusKooste {
  text-align: center;
  margin: 1rem 0;
}
.SaadosOtsikkoKooste {
  text-align: center;
  font-weight: bold;
  margin: 1rem 0;
}
.Pykala .SaadosOtsikkoKooste {
  font-weight: normal;
  font-style: italic;
}
.MomenttiKohtaKooste {
  margin: 0.5rem 0;
}
.LakiehdotusNumeroKooste {
  font-weight: bold;
  font-size: 1.2rem;
}
.HallituksenEsitys .row {
  display: flex;
  justify-content: space-evenly;
}
.Kuva:after {
  content: '[KUVA ALKUPERÄISESSÄ DOKUMENTISSA]';
  display: block;
  margin: 1rem 0;
  padding: 3rem 2rem;
  border: 4px dashed #ccc;
  text-align: center;
  font-size: 1.1rem;
  color: #888;
}
.KohtaNumero,
.KohtaNimeke > span,
.KohtaAsia > span {
  margin-right: 5px;
}
.AjankohtaTeksti {
  margin-right: 5px;
  float: left;
}
.KohtaSisalto {
  padding: 1em;
  margin: 1rem 0;
  border: 1px solid #ccc;
  border-radius: 3px;
}
.KohtaSisalto *:first-child {
  margin-top: 0;
  padding-top: 0;
}
.KohtaSisalto *:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
}
.TarkennusAsemaTeksti {
  margin-right: 5px;
}
.PuheenjohtajaTeksti {
  font-weight: bold;
}
.Asiantuntija {
  margin-left: 2rem;
}
.PykalaViite {
  font-weight: bold;
}
.Allekirjoittaja {
  display: inline-block;
}

/* move all this stuff to own component */
.inner-hit-paper {
  margin-top: 1rem;
}
.inner-hit--container {
  display: block;
  position: relative;
  padding: 1.5rem 2rem 2rem;
  border-bottom: 1px solid var(--divider);
  transition: 0.2s ease-out;
  color: var(--text-color-dark);
}

.inner-hit--title {
  line-height: 1.75rem;
  margin: 0;
}
.inner-hit--meta {
  padding: 0.5em 0.5rem 0;
  color: var(--grayish);
  font-size: 0.85rem;
}
.inner-hit--meta * {
  margin: 0.5em;
}

.inner-hit--snippets {
  padding: 0.5em 0.5rem 0;
  font-size: 0.8rem;
}

/* TODO */
.inner-hit--url a {
  text-decoration: none;
}
.inner-hit--url:hover,
.inner-hit--url:hover {
  color: var(--primary);
  text-decoration: underline;
}

