.toc {
  padding-left: 0.8em;
  padding-right: 0.8em;
  border: 1px solid var(--grayish);
  border-radius: 5px;
}
.toc > a:first-child {
    margin-top: 0.8em;
}
.toc > a:last-child {
  padding-bottom: 0.8em;
}

.toc-title {
  padding: 0.1em 1.5em 0.1em 1.5em;
  min-height: 3.5em;
  background-color: var(--light-green);
}

.toc-link {
  display: block;
  margin-left: 0.5em;
  font-size: 0.9em;
}


.toc-root {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}

.toc-sub {
  margin-left: 1.4em;
  font-weight: bold;
}

.toc-sub-sub {
  margin-left: 2.8em;
}

.toc-laki {
    font-weight: bold;
}

.min-max-toc{
    margin-top: 0.3em;
}
.toc-icon {
    margin-top: 0.6em;

}
.min-max-toc:hover {
    cursor: pointer;
}

.hidden {
    display: none;
}

.anchored-toc {
  position: fixed;
  max-height: 90%;
  overflow-y: auto;
  width: 25%;
  max-width: 350px;
}

.anchored-toc .block {
  margin-bottom: 0;
}

.anchored-minimized-toc {
  position: fixed;
  overflow-y: hidden;
}

.toc {
  padding: 0em;
}