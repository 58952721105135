.block ul .listitem--wordcount, .listitem__type {
    margin-bottom: 0.5rem;
    padding-left: 0;
}

.listitem__larger {
    font-size: 1.2rem;
    color: var(--text-color-dark);
}

.listitem__count {
    font-size: 1rem;
    float: right;
}

.listitem__link, .listitem__date {
    font-size: 0.85em;
}

.listitem__date {
    display: block;
}

