.document-type-select {
  background: white;
  margin: 0;
  padding: 1rem;
  border: 1px solid var(--divider);
  margin-bottom: -1px;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  cursor: pointer;
  color: var(--primary);
  font-size: 1rem;
  font-weight: 500;
  margin-right: -1px;
}
.document-type-select:last-child {
  margin-right: 0;
}
.document-type-select--active {
  color: black;
  background-color: var(--background);
  border-bottom: 1px solid transparent;
}
.document-type-select--active:hover {
  text-decoration: none;
}
@media only screen and (max-width: 729px) {
  .document-type-select {
    width: 50%;
  }
}
.document-type-mobile-select-label {
  background: none;
  padding: 0 1rem 1rem;
  margin: auto;
  text-transform: uppercase;
}
.document-type-mobile-select .MuiSelect-select {
  color: var(--primary-dark);
  padding-bottom: 24px;
  font-size: 1.2rem;
  font-weight: 400;
}
.intro-info {
  font-size: 1rem;
  color: var(--text-color-light);
  max-width: 850px;
  letter-spacing: 0.017rem;
  line-height: 1.625rem;
}

.fab-navigation {
  visibility: visible;
  margin: 0px;
  z-index: 3;
  /* top: auto; */
  right: 100px;
  bottom: 150px;
  left: auto;
  position: fixed !important;
}

.search-result-title {
  margin-top: 50px;
  margin-bottom: -20px
}

.fab-hide {
  visibility: hidden;
}

.info-highlight-new {
  margin-top: 2rem;
  text-align:center;
  font-size: 11pt;
}
.info-highlight-new .left-col
{
  display: inline-block;
  width: 25%;
  padding: 1rem;
}

.info-highlight-new .right-col
{
  display: inline-block;
  width: 75%;
  background-color: #FFED65;
  padding: 1rem;
}

@media all and (max-width: 600px)
{
  .info-highlight-new .left-col{
    display:none;
  }
  .info-highlight-new .right-col {
    display: block;
    width: auto;
  }
}
.section-for-everyone{
  margin-left: auto;
  margin-right: auto;
  max-width: 1480px;
  display: flex;
  justify-content: center;
  /* border: 2px solid red; */
  padding-left: 1rem;
  padding-right: 1rem;
}
