/* Global custom properties */
:root {
  /* colors */
  --primary: rgb(6, 78, 234); /*= 064EEA hsl(202, 67%, 36%); */
  --primary-dark: hsl(202, 67%, 28%);
  --grayish: hsl(202, 20%, 40%);
  --light-gray: hsl(180, 0%, 96%);
  --hilight: hsl(150, 25%, 90%);
  --hilight-border: hsl(150, 25%, 90%);
  --divider: hsl(202, 15%, 82%);
  --background: hsl(210, 5%, 96%);
  --primary-blue: rgb(6, 78, 234);
  --text-color-light-old: rgb(70, 68, 68);
  --text-color-light: #656565;
  --text-color-dark-old: rgb(7, 7, 7);
  --text-color-dark: #070707;
  --gray-dark: #9D9D9D;
  --gray-mid: #DCDCDC;
  --gray-light: #F4F5F5;
  --gray-verylight: #CECECE;
  --light-green: #ACE7CA;
  --yellow: #FFED65;
  --pink: #FFCAE9;
  --blue-verylight:rgb(162, 188, 242);
}

html {
  font-size: 16px; /*100%;*/
  background-color: var(--gray-light);
}

body {
  margin: 0;
  padding: 0;
  overflow-y: scroll; /* quick fix to scrollbar bumping content */
  background-color: white;
  color: var(--text-color-dark);
}

/* prevent focus ring when using mouse (but not when using keyboard) */
body.using-mouse :focus {
  outline: none;
}

.App {
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.025em;
  font-family: "Libre Franklin", "Helvetica Neue", helvetica, arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  .section--header,
  .section--info-area,
  .section--content
  {
    padding-left: 0rem;
    padding-right: 0rem;
  }
}
@media only screen and (max-width: 576px) {
  .App {
    .section--header,
    .section--info-area,
    .section--content
    {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1 {
  font-size: 3rem;
  line-height: 3.75rem;
  margin: 16px 0;
  font-weight: 600;
  letter-spacing: 0.07rem;
  color: var(--text-color-dark);
}
h2 {
  font-size: 1.825rem;
  margin: 16px 0;
  font-weight: 600;
}
h3 {
  font-size: 1.3rem;
  margin: 16px 0;
  font-weight: 500;
}
h4 {
  font-size: 16px;
  margin: 16px 0;
  font-weight: semi-bold;
}
h5 {
  font-size: 1.1rem;
  font-weight: 500;
}

h1.page-title {
  text-align: center;
}
@media only screen and (max-width: 576px) {
  h1 { font-size: 1.3rem; }
  h2 { font-size: 1.25rem; }
  h3 { font-size: 1.2rem; }
  h4 { font-size: 1.15rem; }
  h5 { font-size: 1.1rem; }
}

a {
  color: var(--primary);
  text-decoration: none;
}
a:hover, a:focus {
  color: var(--primary-dark);
  text-decoration: underline;
}

button:hover {
  text-decoration: underline;
}

img {
  max-width: 100%;
}

em, .highlight {
  padding: 1px 3px;
  border-radius: 1px;
  background: var(--hilight);
  font-style: normal
}
p {
  max-width: 1000px;
}
.tippable:after {
  display: inline-block;
  content: "i";
  font-family: Arial, Helvetica, sans-serif;
  text-transform: none;
  width: 16px;
  margin-left: 0.5rem;
  height: 16px;
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0;
  flex-shrink: 0;
  border-radius: 50%;
  color: white;
  background: var(--primary-blue);
  position: relative;
  left: 0.25em;
  top: -0.5em;
  text-align: center;
  opacity: 1;
  transition: opacity 0.2s ease-out;
  }
.tippable:hover:after, .tippable:focus:after {
  opacity: 1;
  transition: opacity 0.4s ease-in;
}

.button-icon {
  margin-right: 2px;
  margin-left: 1px;
  margin-bottom: 2px;
  /*color: var(--grayish);*/
  /* color: white; */
  /* transform: scale(0.8); */
}

.info-highlight {
  display: flex;
  justify-content: center;
  margin: 16px 32px;
  font-size: 1.2em;
}
.info-highlight .info-highlight__icon {
  margin: 0 8px -10px 0;
  font-size: 32px;
}

/* for throbbers etc. */
.fade-in {
  animation: fadeIn 2s ease-out;
}

@keyframes fadeIn {
  0% {
      opacity: 0;
  }
  10% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}
  
