.timeline-wrapper {
    padding: 2rem;
}
.title_notHE {
    font-size: 2rem; 
    /*line-height: 1rem; */
    color: var(--primary-dark);
}
.asiakirja{
    font-size: 2rem;
}
.hanke {
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid var(--divider);
}
    
.hanke__title {
    margin-top: 0;
    margin-bottom: 0;
}

.timeline-header {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}
.timeline-header__title {
    text-transform: uppercase;
    margin-top: 0;
    font-size: 1em;
}
.timeline-header .timeline-header__search {
    margin: -20px 0 0 16px;
}
.timeline-header__button-area {
    flex-grow: 1;
    margin-top: -5px;
    margin-bottom: 5px;
    text-align: right;
}
.timeline-listview {
    padding: 1rem 0;
}

@media screen and (max-width: 600px) {
    .timeline-header {
        display: block;
    }
    .timeline-header .timeline-header__search {
        margin: 0 0 8px;
    }
    .timeline-header__button-area {
        text-align: left;
        margin: 0 0 8px;
    }
}