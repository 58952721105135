.page-wrapper {
  /*padding-: 2em;*/
  margin-bottom: 2rem;
}
.page-wrapper-mobile {
  margin: 1rem;
}
.page-title {
  margin-top: 0;
  /*text-transform: uppercase;*/
}
.page-subtitle {
  margin-top: 3rem;
  margin-bottom: 2rem;
}
.big-link {
  display: inline-block;
  font-size: 1.2em;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
}
.big-link:before {
  content: '▸ ';
}
.embed-video {
  max-width: 100%;
  margin-bottom: 1rem;
  border: 0px;
}

.page-title-version-number {
  float: right;
  font-size: 0.6em;
}
