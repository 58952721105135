.block{
  padding: 0px;
}
.block-closed{
  padding: 0px;
  margin-bottom: 0px;
}
.paper{
  width: 100%;
}
.ordering-div{
  display:flex;
  align-items:center;
  justify-content:center;
  direction:row;
}
.arrow-icon-div{
  max-width: 1.5rem;
}
.facet-header-wrapper {
  display: flex;
  direction: row;
  justify-content: space-between;
  align-items: center;
}
.facet-header-wrapper h4 {
  font-size: 0.85rem;
  font-weight: bold;
}
.facet-header {
  display: inline-block;
  cursor: pointer;
  text-align: right;
  background: none;
  border: none;
  padding: 0;
}
.facet-header:hover {
  opacity: 0.8;
  text-decoration: none;
}
.facet-header--primary {
  max-width: 80%;
}
.pieces-and-not-this{
  display: flex;
  direction: row;
  justify-content: space-between;
}
.facet-header--secondary {
  max-width: 100%;
  font-size: 1rem;
  right: 0;
  bottom: 10px;
  max-height:2rem;
  display: flex;
  justify-self: flex-start;
}
.not-this{
  display: flex;
  align-items: center;
  justify-self: flex-end;
}
.h4{
  display: flex;
  align-items: flex-start;
}
.checkboxIcon{
  font-size: large;
}
.checkedCheckboxIcon{
  font-size: large;
  color: rgba(6,78,234,0.5);
}
.checkbox-label {
  font-weight: normal;
  cursor: pointer;
  /* display: inline-block; */
  width: 100%;
  line-height: 1.7rem;
  display: flex;
  justify-content: flex-end;
  color: var(--primary);
}
/* input:checked ~ .checkbox-label {
  color: var(--primary-dark);
} */
.facet-checkbox {
  /* position: relative; */
  max-width: 50%;
  /* padding-right: 16px; */
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.facet-iconbutton{
  display:flex;
  justify-content: flex-start;
  min-width: 70%;
  padding-left:0px;
  margin-left: 0px;
}
.facet-checkbox input {
  appearance: none;
  /* position: absolute; */
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  opacity: 0;
}
.facet-checkbox input ~ .facet-check {
  /* position: absolute; */
  top: 0;
  bottom: 0;
  left: -5px;
  right: -5px;
  background-color:transparent;
  transition: background-color 0.1s ease-in-out;
  border-radius: 2px;
  z-index: 0;
  cursor:pointer;
}
.facet-checkbox input:checked ~ .facet-check {
  /* background-color: var(--hilight); */
  background-color: rgba(6,78,234,0.3);
}
.facet-checkbox input:focus ~ .facet-check {
  border: 2px dotted var(--primary);
}
.facet-checkbox label {
  /* position: relative; */
  /* z-index: 1; */
  /* display: inline-block;  */
  max-height: 100%;
}
.icon-div{
  display: flex;
  justify-content: flex-start;
  margin-left:0;
}
.facet-name {
  display: flex; 
  justify-content: flex-start;
  color: black;
  width: 100%;
  max-height: 100%;
  line-height: 1em;
  font-size: 0.85rem;
  margin-left:0.3rem;
}
.X{
  width:1em;
}
.facet-name:hover,
.facet-name:focus {
  text-decoration: underline;
}
.facet-count {
  float: right;
  justify-content: flex-end;
  color: var(--grayish);
  font-size: 0.85rem;
}

.facet-arrow-up {
  margin-bottom: -0.425em;
}
.facet-arrow-down {
  margin-top: -0.425em;
}
.facet-checkbox--container {
    display: flex;
    /* padding-left: 1rem; */
    max-height: 1.7rem;
    justify-content: space-between;
}
.hidden {
    display: none;
}
.search-without {
  text-decoration: line-through;
}