/* BLOCKS */

.block {
    /* position: relative; */
    /* padding: 16px 32px 32px; */
    margin-bottom: 32px;
    padding: 1em;
}

.block--facet {
    /*padding: 16px 16px 32px;*/
    padding: 0;
}

.block--title {
    text-transform: uppercase;
    border-bottom: 1px solid var(--divider);
    padding-bottom: 10px;
    font-size: 1.1rem;
}
.block ul {
    list-style-type: none;
    padding-left: 0;
    color: var(--grayish);
    position: relative;
    margin-bottom: 0;
}
.block ul li {
    margin-bottom: 15px;
    padding: 0;
  }
.block ul li:last-child {
  margin-bottom: 0;
}
.block ul li a{
  text-decoration: none;
}
.block ul li a:hover,
.block ul li a:focus {
  text-decoration: underline;
}
  
