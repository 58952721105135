.result-charts {
  margin: 16px;
  padding: 0 16px 16px;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  overflow-x: auto;
}
.chart-wrapper {
  margin: 1rem 0;
}

.chart-wrapper.chart-wrapper-fullwidth {
  width: 100%;
}

.chart-title-wrapper {
  margin: 2rem 0 0.5rem;
  text-align: center;
}
.chart-title {
  display: inline-block;
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: normal;
}
.button--chart {
  float: right;
  z-index: 10;
}
.result-charts .rv-xy-plot rect {
  cursor: pointer;
}
.result-charts .rv-xy-plot rect:hover {
  outline: 2px solid #e95;
}
.flexContainer
{
  width: 100%;
}