.timeline__section-title {
    border: none;
    background: none;
    margin: 0;
    font-weight: 500;
    font-size: 1.1em;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.87);
}
.timeline__section-title .timeline__arrow-icon {
    font-size: inherit;
    margin-bottom: -5px;
    margin-left: -5px;
    font-size: 1.3rem;
    color: var(--primary);
}
.timeline__list-wrapper {
    padding: 0 8px;
}
.timeline__list {
    margin-top: 4px;
}
.timeline__listitem--header {
    font-weight: bold;
    margin-top: 16px;
    margin-bottom: 4px;
}
.timeline__listitem--header a {
    text-decoration: inherit;
}
.timeline__listitem--header a:hover,
.timeline__listitem--header a:focus {
    text-decoration: underline;
}
.timeline__field {
    margin-bottom: 8px;
    padding-left: 16px;
    font-size: 0.9rem;
}
.timeline__field-label {
    text-transform: uppercase;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.87);
    font-size: 0.8rem;
}
.timeline__doc-count {
    font-size: 0.8em;
}
body .timeline__tooltip {
    background-color: #444;
    color: #FFF;
    border-radius: 3px;
    padding: 16px;
    font-size: 0.9rem;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2),
        0px 2px 2px 0px rgba(0,0,0,0.14),
        0px 3px 1px -2px rgba(0,0,0,0.12);
}
.timeline__section-title--empty,
.timeline__section-title--empty .timeline__arrow-icon {
    color: rgba(0, 0, 0, 0.5);
}