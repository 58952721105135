.pagination {
    padding: 5px;
    margin: 1rem 0;
    text-align: center;
    
  }
  .pagination-link {
    display: inline-block;
    padding: 5px 10px;
    border: none;
    background: none;
    font-size: 0.9rem;
    color: var(--primary);
    cursor: pointer;
  }
  .pagination-link--active {
    color: var(--grayish);
    cursor: default;
    text-decoration: underline;
  }
  .pagination-link:hover {
    text-decoration: underline;
  }
  