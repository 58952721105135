.tags {
    margin: 1rem 0;
    font-size: 0.8rem;
    background-color:  var(--light-gray);
    padding:0.5em;
  }
  .tags--results {
    margin-bottom: 0;
    font-size: 0.8rem;
    background-color:  var(--light-gray);
    padding: 0.6em;
  }
  .tag {
    display: inline-block;
    color: var(--grayish);
    margin: 0 0.5rem 0 0;
    font-weight: normal;
  }
  .tag::after {
    content: ", ";
  } 
  .tag:last-child::after {
    content: "";
  }