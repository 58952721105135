.download-form {
  padding: 32px;
}

.download-form__title {
  margin: 0;
  text-transform: uppercase;
}

.download-form__subtitle {
  text-transform: uppercase;
  font-size: 1.1rem;
  margin: 0 0 1em 0;
}

.download-form__inner {
  background: var(--background);
  padding: 32px;
  border: 1px solid var(--divider);
}

.download-form .Mui-checked ~ * {
  font-weight: bold;
}

.download-form__button-wrapper {
  margin-top: 16px;
}

.circular__progress__centered {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5em;
}

.radio-group-format {
  margin-left: 1em;
}

.checkbox-info {
  margin-left: 32px;
  margin-bottom: 16px;
  font-style: italic;
  color: #555;
}

.download-form__button-link {
  display: inline-block;
  padding: 5px 10px;
  border: 1px solid;
  text-transform: uppercase;
}