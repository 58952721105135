.timeline-chart {
  max-width: 100%;
}
@media only screen and (max-width: 1199px) {
  .timeline-chart {
    overflow-x: auto;
    overflow-y: hidden;
  }
}

.timeline-hint {
  max-width: 220px;
}

.timeline-hint div {
  white-space: normal;
}

.timeline-hint__docgroup {
  font-size: 0.9em;
  text-transform: uppercase;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding-bottom: 2px;
  margin-bottom: 2px;
}

.timeline-hint__title {
  margin-bottom: 5px;
}

.timeline-hint__date {
  font-size: 0.9em;
}

.timeline-chart circle {
  cursor: pointer;
}
.timeline-chart circle:hover {
  stroke: #ddd !important;
}
.timeline-legend {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;

  button
  {
    padding: 0;
    margin-bottom: 0;
  }
}
.timeline-legend__item {
  background: none;
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  color: var(--grayish);
  cursor: pointer;
}
.timeline-legend__item--active {
  color: var(--text-color-dark);
}
.timeline-legend__item:hover,
.timeline-legend__item:focus {
  text-decoration: none;
}
.timeline-legend__item__color {
  width: 1em;
  height: 1em;
  margin: 5px;
  border-radius: 2px;
  background: #ccc;
}

.timeline-postfix {
  margin-top: 1rem;
  text-align: center;
  color: var(--grayish);
}

.timeline-postfix__item:not(:first-child) {
  margin-left: 1rem;
  padding-left: 1rem;
  border-left: 1px solid var(--divider);
}
.scroll-bar::-moz-range-thumb{
  height: 11px;
  width: 50%;
  background-color: #2584bb;
}
.scroll-bar::-moz-range-track {
  height: 10px;
  width: 100%;
  margin: 10px 30px;
  background: #ccc;
  border-radius: 16px;
}
.scroll-bar::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  appearance: none; 
  height: 10px;
  width: 100%;
  margin: 10px 30px;
  background: #ccc;
  border-radius: 16px;
}
.scroll-bar::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  height: 11px;
  width: 50%;
  background-color: #2584bb;
}
.scroll-bar-div{
  max-width: 78%;
  display: flex;
  flex-wrap: wrap;
}
.button{
  flex-grow: 1;
  margin-top: -5px;
  margin-bottom: 5px;
  text-align: right;
}
