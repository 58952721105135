.docgroup-select {
  border: 2px solid var(--gray-dark);
  border-radius: 3px 0px 0px 3px;  
  padding: 10px 14px;
  width: 70%;
}
.docgroup-info-div{
  margin-top: 3rem;
}
.docgroup-info-source{
  margin-top: 1rem;
}
.doctype-label{
  margin-bottom: 0;
}
.multiselect-div{
  margin-top: 2rem;
}
.prefilters-button-container {
  text-align: left;
  margin: 1rem auto 2rem;
}

.prefilters-form-container {
  box-sizing: border-box;
  display: inline;
}

.prefilters__form-control {
  width: 100%;
  border: 2px solid blue;
}

.prefilters-form .prefilters__form-control--50 {
  width: 100%;
  padding-right: 20px;
  /* border: 2px solid var(--gray-dark); */
  border-radius: 3px 0px 0px 3px;  
  /* margin-right: 20px; */
}
.year-title{
  width: 40%;
  margin-bottom: 2px;
}
.years-div{
  display:flex;
  width:70%
}
.start-year-div{
  width:50%
}
.search-term-title{
  margin-top: 1.5rem;
  margin-bottom: 2px;
}

.prefilters-form {
  margin-bottom: 4rem;
  padding: 0rem;
  overflow-x: visible;
}

.prefilters__form-control
  .prefilters-form__input {
    height: 60px;
    background: white;
    .MuiInputBase-formControl
    {
      border-radius: 3px 0px 0px 3px; 
    }
  }
  .prefilters-form__searchterm {
    width: 100%;
  }
  .prefilters-form__tunnus {
    width: 50%
  }

.prefilters-form .auto-chips {
  white-space: normal;
}

.buttons-div{
  margin-top: 4rem;
}
.buttons-div .prefilters-button {
    width: 190px;
    margin-right: 1rem;
  }
.buttons-div .reset-prefilters{
    width: 190px
  }

.prefilters__legend {
  margin-bottom: 8px;
}

@media only screen and (min-width: 960px) {
  .prefilters-form-container {
    z-index: 5;
    height: 400px;
    max-height: 0px;
    opacity: 0;
    transition-duration: 300ms;
    transition-timing-function: ease-out;
    transition-property: max-height, opacity;
  }
  .prefilters-form-container--open {
    transition-duration: 500ms;
    max-height: 800px;
    height: auto;
    opacity: 1;
  }
}
