.footer-container {
  line-height: 1.8rem;

  a {
    text-decoration: underline;
    color: black;
  }
}
.footer__link {
  display: block;
}
.twitter__link,
.blog__link {
  padding: 0.5em;
}
.twitter__logo
{
  width: 1.25em;
}
.blog__logo {
  width: 1.75em;
}
