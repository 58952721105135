.show-more-toggler {
    display: flex;
    align-items: center;
    margin-top: 0.75rem;
    font-size: 0.9rem;
    text-decoration: underline;
    cursor: pointer;
    background: none;
    border: none;
    padding-left: 2rem;
    color: var(--primary);
  }