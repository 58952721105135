.listblock--static {
  border: 2px solid var(--pink);
  border-radius: 5px;
  font-size: 10pt;
}

.listblock--item-container {
  font-weight: normal;
  display: flex;
  width: 100%;
  line-height: 2rem;
  position: relative;

  .MuiButton-label {
    justify-content: left;
  }
}
.listblock--item-key {
  display: inline-block;
  max-width: 80%;
  color: var(--grayish);
}

.listblock--item-value {
  margin-left: auto;
  color: var(--grayish);
}

.listblock--static {
  padding: 1em;
}
.listblock--static-mobile{
  margin: 1rem;
  border: 2px solid var(--pink);
  border-radius: 5px;
  font-size: 10pt;
  padding: 0.5em;
}

.listblock--bordered {
  padding: 1em;
  border: 2px solid var(--pink);
  border-radius: 5px;
  font-size: 10pt;

  .show-more-toggler {
    padding-left: 4px;
  }
}

.listblock--info {
  margin: 0.5em;
}

.anchored {
  position: fixed;
  width: 25%;
  max-width: 350px;
}
.anchored .block {
  margin-bottom: 0;
}

.anchored--first {
    z-index: 100;
  top: 20px;
}
.anchored--second {
  top: 500px;
}

.listblock--backward-search-icon {
    width:0.5em !important;
    height:0.5em !important;
}
