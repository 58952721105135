.errorbar {
    color: white;
    background: #c02424;
    display: flex;
    padding: 1rem;
    border-radius: 3px;
    box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 3px 1px -2px rgba(0,0,0,0.12);
  }
  .errorbar--text {
    margin: 0 10px;
  }
  