.block--siblings {
  padding: 1em;
  border: 2px solid var(--pink);
  border-radius: 5px;
  font-size: 10pt;

  .show-more-toggler {
    padding-left: 4px;
  }
}
.sibling {
    margin-bottom: 0.5rem;
}
.siblings-paper-mobile{
    margin:1rem;
}

.sibling__date {
    font-size: 0.9em;
    font-weight: bold;
}

.sibling__link {
    display: block;
}

.sibling__link--active {
    font-weight: bold;
    font-style: italic;
}