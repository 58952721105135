.site-name-area {
  margin: 0;
  text-align: left;
  display: flex;
  
  max-width: 1480px;
  margin-left: auto;
  margin-right: auto;
}

.site-name-left {
  flex: 1;
  text-align: left;
}
.site-name-right {
  flex: 1;
  text-align: left;
}

.site-name {
  font-size: 2.8125rem;
  display: inline-block;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  font-weight: 200;
  margin-bottom: -8px;
}
.site-slogan {
  font-size: 1rem;
  margin-top: 0.6rem;
  font-weight: normal;
  color: #313d46;
}
.link-to-front,
.link-to-front:hover,
.link-to-front:focus {
  text-decoration: none;
  color: #313d46;
}

.site-name-seperator {
  height: 100%;
  display: inline-block;
  margin: 0 1em 0 2em;
  border-left: solid #1e6c99;
}

.navigation-bar
{
  border-top: 1.5px solid var(--gray-verylight);
  border-bottom: 1.5px solid var(--gray-verylight);
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.site-name-navigator {
  vertical-align: top;
  display: inline-block;
  top: 0;
  font-size: 1rem;
  
  margin-top: 1.125rem;
  margin-bottom: 1.125rem;
}
.site-name-navigator > a {
  display: inline-block;
  cursor: pointer;
  padding: 0 2em;
  color: black;
}

@media only screen and (max-width: 588px) {
  .site-name-area {
    display: block;
  }
  .site-name-seperator {
    display: none;
  }
  .site-name-left {
    text-align: left;
  }
  .site-name-navigator > a {
    display: inline-block;
    padding: 0.4em 0.6em;
  }
}

div.site-name-navigator > a.selected,
div.language-selector > button.selected {
  color: var(--primary-dark);
  text-decoration: underline;
}

.language-icon {
  margin-bottom: 0.5em
}
/* TogglerMenu.css */
.toggler-menu {
  position:static;
}

.toggler-button {
  background-color: white;
  color: #333;
  border: none;
  font-size: 30px;
  padding: 5px;
  cursor: pointer;
  width: 50px;
}

.menu-nav {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
  overflow-x: hidden;
  transition: 0.3s;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  z-index: 999;
}

.menu-nav.open {
  width: 250px;
}

.menu-nav ul {
  list-style-type: none;
  padding: 0;
}

.menu-nav li {
  padding: 8px 16px;
  text-align: left;
}

.menu-nav a {
  text-decoration: none;
  color: white;
  display: block;
  font-size: 24px;
}

.menu-nav a:hover {
  background-color: #575757;
}