.doc-info {
    /*text-transform: uppercase;*/
    font-size: 1rem;
    color: var(--grayish);
}
.doc-info--lower {
    font-size: 0.8rem;
    color: var(--grayish);
    display: block;
    font-weight: bold;
    margin-bottom: 0.2rem;
}
.doc-info--results {
    margin-bottom: 0.5rem;
}
.info-field--key {
    font-size: 0.9rem;
}
.info-field--value {
    font-size: 0.9rem;
    font-weight: 750;
}
.info-field:not(:last-child) {
    padding-right: 0.5rem;
    margin-right: 0.5rem;
    border-right: 1px solid var(--divider); 
}
