/* general */

.section {
    margin: 0;
}
.section__inner {
    width: 100%;
    margin: 0 auto;
    text-align: left;
    padding: 1rem 0rem ;
    max-width: 1480px;
    margin-left: auto;
    margin-right: auto;
}

/* special */

/*.section--header {
    background-color: white;
    padding: 0rem 0 1rem;
}*/
.section--header__inner {
    padding-bottom: 0;
}
.section--info-area {
    background-color: white;
    padding-bottom: 0;
    margin-top: -1rem;
    line-height: 1.5;
}
.section--info-area-h2
{
/*    margin-top: 2rem;*/
    margin-bottom: 2rem;
}
.section--info-area__inner {
    max-width: 1480px;
    margin-left: auto;
    margin-right: auto;
}
.section--content {
    margin-bottom: 1rem;
}
.section--document {
    margin-bottom: 1rem;
}
.section--timeline {
    padding: 16px 0 0;
    border-top: 1px solid var(--divider);
}
.section--select-area {
    background-color: white;
}
.section--select-area--mobile {
    text-align: center;
}
.section--select-area__inner {
    text-align: center;
    padding-top: 0;
    padding-bottom: 0;
}
.section--select-area--mobile__inner {
    padding: 0;
}
.section--page {
/*    padding-top: 2rem;
    border-top: 1px solid var(--divider);*/
    
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}
/* .section--page__inner {
    max-width: 1200px;
}
 */
.section--footer {
    margin-top: 1rem;
    padding: 2rem;
    font-weight: bold;
    background-color: #F4F5F5;
}

.section--footer__inner {
    padding: 2rem 0rem;
    max-width: 1480px;
    margin-left: auto;
    margin-right: auto;
}
/* @media only screen and (min-width: 600px) {
    .section--document {
      padding-top: 2rem;
    }
  } */
