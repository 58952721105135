.search-form-wrapper-for-everyone {
  /* margin: 0rem auto 1rem; */
  /* margin-left: 5%; */
  /* margin-right:5%; */
  margin: 0rem auto 1rem;
}
.search-form-wrapper{
  margin: 0rem auto 1rem;
}
.form-type-select-area {
  text-align: left;
  margin-bottom: 1rem;
  border-bottom: 2px solid lightgray;
  border-collapse: collapse;
}
.form-type-select {
  background: none;
  margin: 0;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  color: var(--text-color-light);
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: -2px;
}
.form-type-select:hover {
  color: var(--primary-blue);
  border-bottom: 3px solid var(--primary-blue);
  text-decoration: none;
}

.form-type-select--active {
  color: var(--primary-blue);
  border-bottom: 3px solid var(--primary-blue);
}
.form-type-select--active:hover {
  text-decoration: none;
}
.search-docgroup-info {
  max-width: 800px;
  margin: 16px auto 0;
  padding: 1rem;
}
.search-docgroup-info__inner {
  padding: 6px 32px 12px;
}
.info-emphasis {
  text-transform: uppercase;
  font-weight: bold;
  color: var(--primary-dark);
}
.info-emphasis-new{
  font-weight: bold;
}
.MuiTooltip-popper .info-emphasis {
  color: white;
}
.search-form {
  text-align: left;
}
.basic-search-term {
  background: white;
  /*width: 320px;
  max-width: 75%;*/
  width: 100%;

  .MuiInputBase-formControl
  {
    border-radius: 0px;
  }
}
.basic-search-term-for-everyone{
  background: white;
  width: 100%;
  align-self: center;
  .MuiInputBase-formControl
  {
    border-radius: 0px;
  }
}
.basic-search-term fieldset {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  
  border-top: 2px solid var(--gray-dark);
  border-bottom: 2px solid var(--gray-dark);
}
.search-form .submit-basic-search {
  /* min-width: initial;*/
  height: 56px;
  margin-left: -1px; 
  background-color: var(--primary);
  color: white;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.search-form .submit-basic-search:hover,
.search-form .submit-basic-search:focus {
  background-color: var(--primary-dark);
}
.proximity-form-div{
  width:80%;
}
.search-form--proximity{
  display:flex;
  direction: row;
}
.proximity-labels{
  /* width: 300px; */
  margin:0;
  /* width: 100%; */
}
.proximity-submit-grid{
  display: flex;
  align-items: flex-end;
}
.search-form .proximity-search-input {
  background: white;
  /* width: 300px; */
  width: 100%;
  margin-right: -1px;
}
.search-form .search-input--center {
  width: 130px;
  height: 56px;
}
.search-form--select-docgroup-for-everyone {
  width: 100%;
  display: flex;
}
.search-form--select-docgroup-fullscreen{
  width: 100%;
  display: flex;
}
.search-form--select-docgroup{
  width: 100%;
  display: flex;
  max-width: 850px; 
}
.search-form--select {
  border-left: 2px solid var(--gray-dark);
  border-top: 2px solid var(--gray-dark);
  border-bottom: 2px solid var(--gray-dark);
  border-radius: 3px 0px 0px 3px;  
  padding: 10px 14px;
  width: 100%;
  height: 100%;
  /* width: 12rem; */
  background-color: var(--light-gray);
  display: inline-block;
}
.search-form-container{
  display:flex;
  direction: row;
}
.search-term-grid{
  display:flex;
  direction: row;
}
.search-input--left fieldset {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.search-input--center fieldset,
.search-input--right fieldset {
  border-radius: 0;
}
.facets{
  /* margin-right: 10px;  */
  width: 100%;
}
/* active facets */
.facet-active-wrapper {
  margin-bottom: 0.5rem;
}
.facet-chip {
  margin: 0 5px 5px 0 !important;
}

/* Result choosers */
.result-chooser-area {
  color: var(--grayish); 
  display: flex; 
  flex-wrap: wrap;
}
.result-chooser-grid{
  display: flex;
  align-items: center;
  color: black;
  justify-content: right;
  /* max-height: 50px; */
  flex-wrap: wrap;
}
.select{
  display: flex;
  justify-content: right;
  align-items: center;
  margin-right: 10;
}
.select .select-order{
  border: 2px solid var(--gray-dark);
  border-radius: 5px 5px 5px 5px; 
  align-items: center;
  margin-right: 10px;
  max-height: 50px;
}
.select .select-number{
  display: flex;
  border: 2px solid var(--gray-dark);
  border-radius: 5px 5px 5px 5px; 
  align-items: center;
  margin-right: -10px;
  max-height: 50px; 
}
.result-chooser-wrapper {
  margin: 0 10px;
}
.result-chooser-wrapper-totalhits{
  display: flex;
  align-items: center;
  justify-self: left;
  color: black;
  max-height: 50px;
}
.search-form-result-chooser{
  padding: 10px 14px;
  max-height: 37px;
}
.select .select-order,
.select .select-number {
  padding: 0px 0px 0px 10px;
}
.facet-grid{
  flex-wrap: wrap;
  display: flex;
}
.result-chooser-wrapper:last-child {
  margin-right: 0;
}
.option:hover {
  background-color: var(--grayish);
}
.search-info {
  margin-bottom: 1.5rem;
  margin-top: 1.5rem;
}
.search-info-blue {
  color: #064eea;
  font-style: italic;
  font-size: 30px;
  font-weight: normal;
}
.search-info-black {
  color: black;
  font-size: 30px;
  font-weight: 600;
}
.button-area {
  display: flex;
  justify-content: flex-end;
}
.download-form-wrapper {
  height: 700px;
  max-height: 0;
  overflow: hidden;
  opacity: 0;
  transition-duration: 400ms;
  transition-timing-function: ease-out;
  transition-property: max-height, opacity;
}
.download-form-wrapper--open {
  max-height: 700px;
  transition-duration: 600ms;
  height: auto;
  overflow: visible;
  opacity: 1;
  margin-bottom: 32px;
}

.fetch-all-clear-all-button {
  padding: 1em;
}
.search-form--prompt
{
  margin-top: 2rem;
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0rem;
}
.tooltip-help
{
  margin: 0;
  padding: 0;
  margin-bottom: 0.2rem;
  margin-left: 0.25rem;
  height: 1.1rem;
  vertical-align: middle;
}
.proximity-doctype-div{
  margin-top: 2rem;
  margin-bottom: 2rem;
  width:75%;
}
.proximity-doctype-h4{
  margin-bottom: 0;
}
.circular-progress{
  display: flex;
  justify-content: center;
}
.white-button{
  display: flex;
  justify-content: center;
}