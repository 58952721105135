.results-area a {
  text-decoration: none;
}
.result {
  display: block;
  position: relative;
  padding: 1.4rem;
  margin: 1em 0em;
  transition: 0.2s ease-out;
  color: var(--text-color-dark);
  border-radius: 5px;
  border: 1px solid var(--divider);
}
.result-title-link:hover,
.result-title-link:hover {
  color: var(--primary);
  text-decoration: underline;
}
.result-title {
  line-height: 1.75rem;
}
.result-index {
  display:none;
  position: absolute;
  top: 3px;
  left: 6px;
  color: var(--grayish);
  font-size: 0.95rem;
}
.result-snippets {
  font-size: 0.9rem;
  margin-top: 1rem;
}

.result-status {
  margin-bottom: 0.2rem;
  font-size: 1rem;
  line-height: 1em;
  font-weight: 500;
  /* color: var(--grayish); */
  float: right;
}
.result-highlighted {
    background-color: #d4fff1;
    border: 3px solid var(--divider);
}
